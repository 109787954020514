const investmentContract = {
  state: {
    pageType: 'list',
    beginUpdate: new Date(),
    id: null,
    contractInfo: {},
    currentStep: 'basic'
  },
  mutations: {
    set_contract_pageType (state, data) {
      state.pageType = data
    },
    set_contract_update (state, data) {
      state.beginUpdate = data
    },
    set_contract_id (state, data) {
      state.id = data
    },
    set_contract_contractInfo (state, data) {
      state.contractInfo = data
    },
    set_contract_currentStep (state, data) {
      state.currentStep = data
    }
  },
  getters: {

  },
  actions: {

  }
}
export default investmentContract
